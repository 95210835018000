
/* Owl Nav Design */
.product-carousel .owl-nav {
    position: absolute;
    top: 0%;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .product-carousel .owl-nav .owl-prev,
  .product-carousel .owl-nav .owl-next {
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
  }
  
  .product-carousel .owl-nav .owl-prev {
    left: 92%;
    transform: translateX(-50%);
  }
  
  .product-carousel .owl-nav .owl-next {
    right: 2%;
    transform: translateX(50%);
  }



  @media only screen and (min-width: 768px) and (max-width: 1100px) {


    .product-carousel .owl-nav .owl-prev {
      left: 90%;
      transform: translateX(-50%);
    }

    .product-carousel .owl-nav .owl-next {
      right: 1%;
      transform: translateX(0%);
    }

  }
  



  @media only screen and (max-width: 767px) {

    .product-carousel .owl-nav {
      top: 3%;
    }

    .product-carousel .owl-nav .owl-prev {
      left: 82%;
      transform: translateX(-50%);
    }
    
    .product-carousel .owl-nav .owl-next {
      right: 3%;
      transform: translateX(0%);
    }
  

  }