.admin-login-container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .admin-login-container h2 {
    margin-bottom: 20px;
  }
  
  .admin-login-container input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .admin-login-container button {
    width: 100%;
    padding: 0.5rem;
    background-color: #DC2626;
    color: white;
    border: none;
    border-radius: 1rem;
    cursor: pointer;
    transition: ease-in-out 0.5s;
    border: #DC2626 1px solid;
    font-weight: bold;
  }
  
  .admin-login-container button:hover {
    background-color: white;
    color: #DC2626;
  }
  