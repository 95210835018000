
/* Owl Nav Design */
.owl-nav {
    position: absolute;
    top: 30%;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .owl-nav .owl-prev,
  .owl-nav .owl-next {
    background-color: rgba(0, 0, 0, 0.5); /* Add background color to make the arrows visible */
    border-radius: 50%;
    color: white;
    cursor: pointer;
    position: absolute;
    
  }
  
  .owl-nav .owl-prev span,
  .owl-nav .owl-next span {
    font-size: 5rem;
  }
  
  .owl-nav .owl-prev {
    left: 0;
    transform: translateX(-50%);
  }
  
  .owl-nav .owl-next {
    right: 0;
    transform: translateX(50%);
  }


  @media only screen and (min-width: 768px) and (max-width: 1100px) {

    .owl-nav {
      top: 20%;
    }

  }

  @media only screen and (max-width: 767px) {

    .owl-nav {
      top: 30%;
    }

    .owl-nav .owl-prev span,
    .owl-nav .owl-next span {
      border-radius: 50%;
      color: rgba(255, 255, 255, 0.7);
      cursor: pointer;
      position: absolute;
      
    }
    
  }

  @media (min-width: 1920px) {

    .owl-nav {
      top: 40%;
    }

  }


  /* Apply styles when the viewport height is at most 800px */
  @media (max-height: 110px) {
    
  }


  