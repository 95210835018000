/* Styles for the Carousel */
.custom-carousel .carousel .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px; /* Adjust height as needed */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .custom-carousel .carousel .slide img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  
  .custom-carousel .carousel .thumbs-wrapper {
    margin-top: 20px;
    padding: 0 20px;
  }
  
  .custom-carousel .carousel .thumb {
    margin: 0 5px;
    border-radius: 5px;
    border: 2px solid transparent;
    transition: border-color 0.3s;
  }
  
  .custom-carousel .carousel .thumb:hover,
  .custom-carousel .carousel .thumb.selected {
    border-color: #A97D5D; /* Replace with your brand color */
  }
  
  /* Thumbnail images should be well-aligned and spaced */
  .custom-carousel .carousel .thumb img {
    height: 75px; /* Adjust as needed */
    width: 75px;
    object-fit: cover;
    border-radius: 5px;
  }
  