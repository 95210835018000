/* Taiwind styles */

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-text-wht {
  font-family: "Poppins", sans-serif;
  font-size: small;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: white;

}

.nav-text-bck {
  font-family: "Poppins", sans-serif;
  font-size: small;
  font-weight: 550;
  line-height: 24px;
  text-align: left;
  color: #181818;

}


.nav-link {
  position: relative;
  text-decoration: none; 
  display: inline-block;
}

.nav-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px; /* Adjust this value to position the underline */
  width: 0;
  height: 2px; /* Thickness of the underline */
  background-color: red; /* Color of the underline */
  transition: width 0.3s ease-in-out;
}

.nav-link:hover::after,
.nav-link-active::after {
  width: 100%; /* Expand the underline on hover or when active */
}


.pp-bnr {
  font-family: "Poppins", sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  text-align: left;
  color: #181818;
}

p {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #515151;

}

.des-wht {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #FFFFFF;

}
.des-gray {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #515151;
}
h1 {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 45px;
  text-align: center;

}

/* h2 other properties is customizable by tailwind in the project */

h2 {
  font-family: 'Poppins', sans-serif;
}

h3 {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 66px;
  text-align: left;
}

h4 {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 50px;
  text-align: left;

}

.topic-bck {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 66px;
  text-align: left;


}


.button-m {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  background: #CC2500;
  color: #FFFFFF;
  border-radius: 9999px;
  padding: 0.5rem 1rem;
  text-align: center;
}

.logo-container {
  max-width: 15%;
}
.header-right {
  max-width: 25%;
}




/* for FAQ SCROLL BAR */

.custom-scrollbar::-webkit-scrollbar {
  width: 1px; /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: rgb(245, 238, 238); /* Gray background line */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, rgba(255,0,0,0) 0%, red 50%, rgba(255,0,0,0) 100%);
  border-radius: 10px; /* Rounded edges */
  box-shadow: 0 0 10px red; /* Glowing red effect */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, rgba(255,0,0,0) 0%, #ff4d4d 50%, rgba(255,0,0,0) 100%);
  box-shadow: 0 0 15px #ff4d4d; /* Enhanced glowing effect when hovered */
}

.custom-scrollbar::-webkit-scrollbar-button {
  display: none; /* Hide the scroll up/down arrows */
}


/* Custom Horizontal Scrollbar Styling */

.custom-scrollbar::-webkit-scrollbar {
  height: 1px; /* Height of the horizontal scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: rgb(245, 238, 238); /* Gray background line */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, rgba(255,0,0,0) 0%, red 50%, rgba(255,0,0,0) 100%);
  border-radius: 10px; /* Rounded edges */
  box-shadow: 0 0 10px red; /* Glowing red effect */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(90deg, rgba(255,0,0,0) 0%, #ff4d4d 50%, rgba(255,0,0,0) 100%);
  box-shadow: 0 0 15px #ff4d4d; /* Enhanced glowing effect when hovered */
}

.custom-scrollbar::-webkit-scrollbar-button {
  display: none; /* Hide the scroll left/right arrows */
}

.card-container {
  position: relative;
  width: 300px; /* Adjust width as needed */
  height: 200px; /* Adjust height as needed */
  perspective: 1000px;
}

.card-front, .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: transform 0.6s;
  border-radius: 10px;
}

.card-back {
  transform: rotateY(180deg);
}

.card-container.flipped .card-front {
  transform: rotateY(180deg);
}

.card-container.flipped .card-back {
  transform: rotateY(0deg);
}

.modal {
  /* Customize your modal style */
}

.overlay {
  /* Customize your overlay style */
}



@media only screen and (min-width: 768px) and (max-width: 1100px) {

  .pp-bnr {
    font-family: "Poppins", sans-serif;
    font-size: 45px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
    color: #181818;
    margin-bottom: 0px;
  }

  .button-m {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    background: #CC2500;
    color: #FFFFFF;
    border-radius: 9999px;
    padding: 0.5rem 1rem;
    text-align: center;
  }
  h1 {
    font-size: 30px;
  }

  .page-title {
    padding-left: 30px;
    padding-right: 30px;
  }

  .title-text {
    font-size: 40px;
  }
  

}



@media only screen and (max-width: 767px) {

  .pp-bnr {
    font-family: "Poppins", sans-serif;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: #181818;
    margin-bottom: 0px;
  }

  .button-m {
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 24px;
    background: #CC2500;
    color: #FFFFFF;
    border-radius: 9999px;
    padding: 0.25rem 0.5rem;
    text-align: center;
  }

  p {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-weight: 400;
  }

  h1,  
  .title-text {
    font-size: 20px;
  }
  h3 {
    
    font-size: 17px;
    
  }
  article {
    font-size: 12px;
  }

  li {
    font-size: 12px;
  }
  .page-title {
    padding-left: 12px;
    padding-right: 12px;
  }
  .logo-container {
    max-width: 35%;
  }

}

@media (min-width: 1536px) {

  .title-text {
    font-size: 40px;
  }

}

@media (min-width: 1920px) {

  h1 {
    font-size: 70px;
    font-weight: 700;
    line-height: 90px;
  }

  .pp-bnr {
    font-size: 100px;
    font-weight: 700;
    line-height: 100px;
  }
  
  p {
    font-size: 28px;
    font-weight: 400;
    line-height: 44px;
  
  }
  
  .des-wht {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  
  }
  .des-gray {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  .button-m {
    font-family: "Poppins", sans-serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 24px;
    padding: 2rem 2rem;
  }

  h3 {
    font-size: 40px;
    font-weight: 700;
    line-height: 140px;
  }

}

@media (min-width: 2400px) {

}

