.admin-dashboard-container {
    padding: 20px;
    text-align: center;
  }
  
  .tabs button {
    padding: 0.3rem 1rem;
    margin: 0 10px;
    border: none;
    background-color: #DC2626;
    color: white;
    cursor: pointer;
    border-radius: 1rem;
    transition: ease-in-out 0.5s;
    border: #DC2626 1px solid;
    font-weight: bold;
  }
  
  .tabs button:hover {
    background-color: white;
    color: #DC2626;
    
  }
  
  .admin-dashboard-container .content {
    margin-top: 20px;
  }
  
  .admin-dashboard-container ul {
    list-style-type: none;
    padding: 0;
  }
  
  .admin-dashboard-container ul li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  