.book-a-call {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
  margin-bottom: 0.4rem;
}

.click-book {
  position: relative;
  padding: 0; /* Remove padding for icon centering */
  font-size: 0; /* Hide text content */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  background-color: #28A745;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.click-book i {
  font-size: 30px; /* Adjust icon size as needed */
  color: white;
  transition: color 0.3s ease;

}

.click-book:hover {
  background-color: white;
  color: #28A745;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Beautiful shadow effect */
}

.click-book:hover i {
  color: #28A745; /* Change color of <i> tag on hover */
}

.book-call {
  position: absolute;
  top: 120%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: bold;
  color: #28A745;
  font-size: large;
  white-space: nowrap;
  width: 200px;
  shape-outside: circle(50%);
  clip-path: circle(50%);
}

@media (min-width: 2000px) {

  .book-a-call {
    margin-bottom: 2rem;
  }

  .click-book {
    height: 150px;
    width: 150px;
  }

  .click-book i {
    font-size: 80px;
  }

  .book-call {
    font-size: xx-large;
    font-weight: bold;
    width: 300px;
  }
  

}



