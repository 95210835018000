/* Container styling */
.why-choose-us-container {
    text-align: center;
    padding: 40px;
    background-color: #f5f5f5;
  }
  
  /* Cards container */
  .cards-container {
    display: grid;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  /* Individual card */
  .card {
    
    perspective: 1000px; /* Allows 3D effect */
  }
  
  .card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .card:hover .card-inner {
    transform: rotateY(180deg);
  }
  
  /* Front of the card (initial state) */
  .card-front, .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden; /* Hides the back when the front is visible */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
  }
  
  .card-front {
    background-position: center;
    background-size: cover;
    background-color: #333;
    color: #fff;
    font-size: 1.5rem;
  }

  .card-front::before {
    content: '';
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 1); /* Semi-transparent black */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)); /* Gradient overlay */
    z-index: 1; /* Make sure the overlay is on top of the background image */
    border-radius: 10px;
  }
  
  .card-back {
    background-color: #fff;
    color: #333;
    font-size: 1rem;
    backface-visibility: hidden;
    transform: rotateY(180deg); /* Start with the back rotated */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .card-description {
    text-align: center;
  }
  